import $ from "jquery";

const OPENED = "opened";
const ALT_OPENED = "Artikel aufklappen für weitere Bilder und Text";
const ALT_CLOSED = "Artikel einklappen für weniger Bilder und Text";
const INNERHTML_OPENED = "schliessen";
const INNERHTML_CLOSED = "mehr erfahren";

$(function () {
  $(
    ".news-container button.read-more-toggle, .news-container button.plus-minus"
  ).on("click", function (e) {
    const newsReadMoreButton = getReadMoreButton(this);

    const closestnewsContainer = newsReadMoreButton.closest(".news-container");

    if (closestnewsContainer.classList.contains(OPENED)) {
      closestnewsContainer.classList.remove(OPENED);

      newsReadMoreButton.alt = ALT_CLOSED;
      //newsReadMoreButton.innerHTML = INNERHTML_CLOSED;
    } else {
      closestnewsContainer.classList.add(OPENED);

      newsReadMoreButton.alt = ALT_OPENED;
      //newsReadMoreButton.innerHTML = INNERHTML_OPENED;
    }
  });
});

function getReadMoreButton(currentTarget) {
  if (currentTarget.classList.contains("read-more-toggle"))
    return currentTarget;
  const newsContainer = currentTarget.closest(".news-container");
  return newsContainer.querySelector("button.read-more-toggle");
}
