import { ready } from "./utils.js";

const OPAQUE = "opaque";

ready(() => {
  const header = document.getElementsByTagName("header")[0];
  const hamburger = document.getElementById("hamburger");

  if (header) {
    document.addEventListener("scroll", function () {
      if (window.scrollY > 5) {
        header.classList.add(OPAQUE);
        if (hamburger) {
          hamburger.classList.add(OPAQUE);
        }
      } else {
        header.classList.remove(OPAQUE);
        if (hamburger) {
          hamburger.classList.remove(OPAQUE);
        }
      }
    });
  }
});
