import { ready } from "./utils.js";

ready(() => {
  const forms = document.getElementsByTagName("form");

  for (let i = 0; i < forms.length; i++) {
    const form = forms[i];
    const allInputs = form.querySelectorAll("input,textarea");

    for (let i = 0; i < allInputs.length; i++) {
      const input = allInputs[i];
      if (input.value.length > 0) {
        input.classList.add("not-empty");
      }

      input.addEventListener("input", ({ target }) => {
        if (target.value.length > 0) {
          target.classList.add("not-empty");
        } else {
          target.classList.remove("not-empty");
        }
      });
    }
  }
});
