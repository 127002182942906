import { ready } from "./utils.js";

ready(() => {
  checkURL();
  addEventListener("hashchange", () => checkURL());
});

function openAndScrollTo(idOfPane) {
  const accordion = document.getElementsByClassName("accordion");
  if ($(idOfPane).length == 1) {
    $(document).one("down.zf.accordion", () => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(idOfPane).offset().top - 300,
        },
        2000
      );
    });
    $(accordion).foundation("down", $(".accordion-content" + idOfPane));
  }
}

function checkURL() {
  const { pathname, hash } = new URL(document.location);
  if (pathname.includes("impressum")) {
    if (hash.includes("datenschutz")) {
      openAndScrollTo("#datenschutz");
    } else if (hash.includes("agb")) {
      openAndScrollTo("#agb");
    }
  }
}
