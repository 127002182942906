import Swiper, { Navigation } from "swiper";
import { ready } from "./utils.js";

ready(() => {
  const swiperMoodbardEl = document.getElementById("swiper-moodboard");
  if (swiperMoodbardEl) {
    const params = new URL(document.location).searchParams;
    const initialSlideIndex = params.get("index");

    const swiperMoodbaord = new Swiper("#swiper-moodboard", {
      modules: [Navigation],
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    if (initialSlideIndex) {
      // need this, because initialSlide is not working with loop: true at the moment (9.5.2022)
      swiperMoodbaord.slideTo(initialSlideIndex);
    }
  }
});
