import $ from "jquery";

$(() => {
  const arrowScroll = document.getElementById("arrow-scroll");

  if (arrowScroll) {
    arrowScroll.addEventListener("click", (e) => {
      if (arrowOverFooter()) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        const pageHeight = window.innerHeight;
        window.scrollBy({ top: pageHeight, behavior: "smooth" });
      }
    });

    window.addEventListener("scroll", () => {
      let rotation = 0;
      if (arrowOverFooter()) {
        rotation = 180;
      }
      arrowScroll.children[0].style.transform = `rotate(${rotation}deg)`;
    });
  }
});

function arrowOverFooter() {
  const footerHeight = document.getElementsByTagName("footer")[0].clientHeight;
  const documentScrollHeight = document.documentElement.scrollHeight;
  const beginningOfFooter = documentScrollHeight - footerHeight;

  const arrowScrollHeight = window.scrollY + window.innerHeight - 50;
  return arrowScrollHeight > beginningOfFooter;
}
